import React from 'react';

const EmptyCategoryPageContent = () => {
    return (
        <div className={'shop-div p-4 w-100'}>
            <h5>
                Hiện chưa có sản phẩm trong danh sách, vui lòng quay lại sau.
            </h5>
        </div>
    );
};

export default EmptyCategoryPageContent;
