let data = {
    cart: [],
    wishlist: [],
    vat: 0, //vat in percentage
    cartTotal: 0,
    orderSuccess: false,
    promoCode: [
        {
            code: 'TENPERCENT',
            percentage: 10
        },
        {
            code: 'FIVEPERCENT',
            percentage: 5
        }
    ],
    usedPromoCode: null,
    deliveryOptions: [
        {
            id: 1,
            name: 'standard',
            duration: '24 - 72 hours',
            cost: 300
        },
        {
            id: 2,
            name: 'fastest',
            duration: '1 - 24 hours',
            cost: 1000
        }
    ],
    productMaxShowModal: false,
    modalMessage: null,
    showSideNavigation: false,
    // used currency should load with the default currency name and rate
    usedCurrency: { VND: 1, symbol: 'đ ' },
    // exchange rates can be got from any api source
    exchangeRates: {
        base: 'VND',
        date: '2019-01-29',
        rates: {
            VND: 1,
            USD: 0.0099
        }
    },
    // overkill but doing it for fun
    currencySymbols: {
        VND: 'đ'
    },
    priceFilter: {
        min: 0,
        max: 5000000,
        pricerange: 100000000
    },
    products: [
        {
            id: 1,
            name: "Bồn cầu INAX AC-959VAN 1 Khối Aqua Ceramic",
            slug: 'ac-959-van',
            price: 500000,
            discount_price: 28000,
            category: 'Thiết bị phòng tắm',
            color: 'black',
            subcategory: '',
            description: 'Chất liệu: Men sứ chống bám bẩn, Thương hiệu: Inax',
            sale: true,
            article: 'boncau',
            quantity: 5,
            img: 'ac959.png',
            folder: 'boncau',
            options: [1, 2, 3],
            vendor: {
                id: 1,
                name: 'Inax'
            },
            ratings: {
                star_ratings: 4.8,
                votes: 350
            }
        },
        {
            id: 2,
            name: 'Bồn cầu INAX AC-969VN',
            slug: 'ac-969vn',
            price: 500000,
            discount_price: 2320,
            category: 'Thiết bị phòng tắm',
            color: 'gray',
            subcategory: '',
            description: 'Chất liệu: Men sứ Thương hiệu: INAX',
            sale: false,
            article: 'handbag',
            quantity: 8,
            img: 'ac969.png',
            folder: 'boncau',
            options: [],
            vendor: {
                id: 2,
                name: 'Inax'
            },
            ratings: {
                star_ratings: 3.6,
                votes: 200
            }
        },
        {
            id: 3,
            name: 'Bồn cầu INAX AC-902VN',
            slug: 'ac-902-vn',
            price: 500000,
            discount_price: 120000,
            category: 'Đèn trang trí',
            color: 'blue',
            sizes: ['S', 'L', 'M'],
            subcategory: 'boys',
            sale: true,
            article: 'boxer',
            quantity: 3,
            img: 'ac902.png',
            folder: 'boncau',
            options: [2],
            vendor: {
                id: 3,
                name: 'vendor three'
            },
            ratings: {
                star_ratings: 2.5,
                votes: 150
            }
        },
        {
            id: 4,
            name: 'Bồn cầu INAX AC-900VRN',
            slug: 'ac-900-vrn',
            price: 500000,
            discount_price: 109800,
            category: 'Thiết bị nhà bếp',
            color: 'brown',
            subcategory: '',
            description: 'Chất liệu: Men sứ Thương hiệu: INAX',
            sale: false,
            article: 'belt',
            quantity: 10,
            img: 'ac900.png',
            folder: 'boncau',
            options: [],
            vendor: {
                id: 4,
                name: 'Inax'
            },
            ratings: {
                star_ratings: 3.8,
                votes: 20
            }
        },
    ]
};

export default data;
