import React from "react";

const CatalogBanner = ({ categories, title }) => {
    return (
        <div id="banner_0" class="grid-catalog mt30 col-sm-12">
            <div class="section-heading">
                <h2>{title}</h2>
            </div>
            <div class="row">
                {categories.map((category) => (
                    <div class="grid-catalog__cell col-xs-6 col-sm-4 col-md-4">
                        <div class="grid-catalog__item">
                            <a href={category.link}>
                                <img
                                    data-src={category.imgURL}
                                    src={category.imgURL}
                                    alt={category.name}
                                    width="373"
                                    height="144"
                                    class="lozad loaded"
                                    data-loaded="true"
                                />
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CatalogBanner;
