import React from 'react';
import { NavLink } from 'react-router-dom';

const FooterLinks = () => {
    return (
        <div className="footer-contaner">
            <div>
                <h5>Liên hệ</h5>
                <ul className="list-unstyled text-small">
                    <li>
                        <NavLink to="/">Nội thất Vương Gia Phú</NavLink>
                    </li>
                    <li>
                        <NavLink to="/">
                            Địa chỉ: Đường Tôn Đức Thắng, khu phố 2, phường Tiến
                            Thành, Tp Đồng Xoài, Bình Phước
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/">
                            ĐT: 0963.345.099 - 0974.391.043
                        </NavLink>
                    </li>
                </ul>
            </div>
            <div>
                <h5>Sản phẩm</h5>
                <ul className="list-unstyled text-small">
                    <li>
                        <NavLink to="/">Thiết bị phòng tắm</NavLink>
                    </li>
                    <li>
                        <NavLink to="/">Thiết bị nhà bếp</NavLink>
                    </li>
                    <li>
                        <NavLink to="/">
                            Đèn trang trí - đèn chiếu sáng{' '}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/">
                            Thiết bị điện Panasonic, Sino, Cadivi, ...
                        </NavLink>
                    </li>
                </ul>
            </div>
            <div>
                <h5>Thương hiệu</h5>
                <ul className="list-unstyled text-small">
                    <li>
                        <NavLink to="/">
                            Toto, Inax, Vigracera, Attax, Haphako
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/">EFFEGI, Karofi, Attax</NavLink>
                    </li>
                    <li>
                        <NavLink to="/">Khaphako</NavLink>
                    </li>
                    <li>
                        <NavLink to="/">
                            Panasonic, Nanoco, Sino, Cadivi, ...
                        </NavLink>
                    </li>
                </ul>
            </div>
            <div>
                <h5>Join Us</h5>
                <ul className="list-unstyled text-small">
                    <li>
                        <NavLink to="/">Zalo</NavLink>
                    </li>
                    <li>
                        <NavLink to="/">Facebook</NavLink>
                    </li>
                    <li>
                        <NavLink to="/">Instagram</NavLink>
                    </li>
                    <li>
                        <NavLink to="/">Tiktok</NavLink>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default FooterLinks;
