import Home from "./Home/Home";
import All from "./All";
import ProductCategories from "./ProductCategories";
import ProductDetails from "./ProductDetails/ProductDetails";
import Sale from "./Sale";
import Cart from "./Cart";
import Checkout from "./Checkout";
import Catalogue from "./CatalogPage/CataloguePage";

export const HomePage = Home;
export const AllPage = All;
export const ProductCategoriesPage = ProductCategories;
export const ProductDetailsPage = ProductDetails;
export const SalesPage = Sale;
export const CartPage = Cart;
export const CheckoutPage = Checkout;
export const CataloguePage = Catalogue;
