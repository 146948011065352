import React from "react";
import PropTypes from "prop-types";
import InputField from "../../UI/Input/InputField";

const customerInputs = (props) => {
    return (
        <React.Fragment>
            <div className="mb-3">
                <InputField
                    label={"Họ và Tên:"}
                    type={"text"}
                    placeholder={"Tên"}
                    identifier={props.customerInfo.firstName}
                    changed={(event) => props.inputChanged(event, "firstName")}
                />
            </div>

            <div className="mb-3">
                <InputField
                    label={"Số điện thoại:"}
                    type={"text"}
                    placeholder={"Số điện thoại"}
                    identifier={props.customerInfo.phoneNumber}
                    changed={(event) => props.inputChanged(event, "phoneNumber")}
                />
            </div>

            <div className="mb-3">
                <InputField
                    label={"Địa chỉ"}
                    type={"text"}
                    placeholder={"Địa chỉ giao hàng"}
                    identifier={props.customerInfo.address}
                    changed={(event) => props.inputChanged(event, "address")}
                />
            </div>
        </React.Fragment>
    );
};

customerInputs.propTypes = {
    inputChanged: PropTypes.func.isRequired,
    customerInfo: PropTypes.object.isRequired,
};

export default customerInputs;
