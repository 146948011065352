import React from "react";
import MenuItem from "../UI/MenuItem/MenuItem";
import PropTypes from "prop-types";

const bathRoomItems = [
    { title: "Sen tắm", linkTo: "/category/thiet-bi-phong-tam/sen-tam" },
    { title: "Bộ Tủ Lavabo", linkTo: "/category/thiet-bi-phong-tam/tu-lavabo" },
    { title: "Lavabo", linkTo: "/category/thiet-bi-phong-tam/lavabo" },
    { title: "Bàn đá lavabo", linkTo: "/category/thiet-bi-phong-tam/bandalavabo" },
    { title: "Vòi lavabo", linkTo: "/category/thiet-bi-phong-tam/voi-lavabo" },
    { title: "Bồn cầu", linkTo: "/category/thiet-bi-phong-tam/boncau" },
    { title: "Bồn tiểu nam", linkTo: "/category/thiet-bi-phong-tam/bon-tieu" },
    { title: "Bồn tắm", linkTo: "/category/thiet-bi-phong-tam/bon-tam" },
    { title: "Phụ kiện", linkTo: "/category/thiet-bi-phong-tam/phu-kien" },
];

const kitchenItems = [
    {
        title: "Chậu rửa chén",
        linkTo: "/category/thiet-bi-nha-bep/chau-rua-chen",
    },
    {
        title: "Vòi rửa chén",
        linkTo: "/category/thiet-bi-nha-bep/voi-rua-chen",
    },
    { title: "Bếp ga", linkTo: "/category/thiet-bi-nha-bep/bep-ga" },
    { title: "Bếp điện", linkTo: "/category/thiet-bi-nha-bep/bep-dien" },
    { title: "Máy hút mùi", linkTo: "/category/thiet-bi-nha-bep/may-hut-mui" },
];
const lightingItems = [
    {
        title: "Đèn chùm",
        linkTo: "/category/den/den-chum",
    },
    { title: "Đèn ốp trần", linkTo: "/category/den/den-op-tran" },
    { title: "Đèn thả", linkTo: "/category/den/den-tha" },
    { title: "Đèn trang trí", linkTo: "/category/den/den-trang-tri" },
    { title: "Đèn khác", linkTo: "/category/den/khac" },
];

const catalogItems = [
    {
        title: "Thiết bị vệ sinh INAX",
        linkTo: "/catalogue/inax",
    },
    { title: "Thiết bị vệ sinh ATTAX", linkTo: "/catalogue/attax" },
    { title: "Thiết bị vệ sinh HAPHAKO", linkTo: "/catalogue/haphako" },
];
const MenuComponent = (props) => {
    return (
        <React.Fragment>
            <MenuItem linkTo={"/all"}>Tất cả</MenuItem>
            <MenuItem linkTo={"/category/thiet-bi-phong-tam"} elements={bathRoomItems}>
                Thiết bị phòng tắm
            </MenuItem>
            <MenuItem linkTo={"/category/thiet-bi-nha-bep"} elements={kitchenItems}>
                Thiết bị nhà bếp
            </MenuItem>
            <MenuItem linkTo={"/category/den-trang-tri"} elements={lightingItems}>
                Đèn trang trí
            </MenuItem>
            <MenuItem linkTo={"/catalogue"} elements={catalogItems}>
                Catalogue
            </MenuItem>
            {/* <MenuItem
                linkTo={'/category/den-trang-tri'}
                elements={lightingItems}>
                Thiết bị điện
            </MenuItem> */}
            {/* <MenuItem linkTo={'/category/ban-an'}>Bàn ăn</MenuItem>
            <MenuItem linkTo={'/category/ban-trang-diem'}>Bàn phấn</MenuItem> */}
            {/* <MenuItem linkTo={'/sale'}>Sale</MenuItem> */}
            <MenuItem linkTo={"/lien-he"}>Liên hệ</MenuItem>
        </React.Fragment>
    );
};

MenuComponent.propTypes = {
    cartCount: PropTypes.number,
};

export default MenuComponent;
