import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import AddToWishList from "../AddToWishlist/AddToWishlist";
// import ProductFeatures from '../../components/ProductCard/ProductFeatures';
import Ratings from "../Ratings/Ratings";
import { currencyToUse, productPrice, productDiscountPrice } from "../../Utility/currency";
import "./ProductCard.css";
import ProductFeatures from "./ProductFeatures";

const ProductCard = (props) => {
    let currencyKeys = currencyToUse(props.currency);
    let currencyValue = currencyKeys.value;
    let currencyName = currencyKeys.name;
    let item = props.product;

    return (
        <React.Fragment>
            <NavLink
                className="btn-block mt-2 shop-card-container"
                onClick={() => {
                    window.scrollTo(0, 0);
                }}
                to={`/${item.productCode}`}
                exact
            >
                <div className="shop-card shadow-sm">
                    <div className="shop-card-image">
                        <NavLink to={`/${item.productCode}`} exact>
                            <img src={item.imageUrl} alt={item.productCode} />
                        </NavLink>
                        {item.sale ? <span className="shop-card-sale">Sale</span> : null}
                        {/* <AddToWishList productId={item.id} title={"add to wishlist"} classStyleName={"shop-card-wishlist"} /> */}
                        {item.discountPrice ? (
                            <span className="shop-card-discount">{productDiscountPrice(item.price, item.discountPrice)}</span>
                        ) : null}
                    </div>

                    <div className="shop-card-content">
                        <h2 className="shop-card-vendor">{item.brand ? item.brand : ""}</h2>
                        <h3 className="shop-card-title">{`${item.name} ${item.productCode}`}</h3>
                        <Ratings
                            ratings={item.ratings}
                            containerClassName={"shop-card-ratings-container"}
                            fullStarIcon={"full-star-icon"}
                            halfStarIcon={"half-star-icon"}
                            emptyStarIcon={"empty-star-icon"}
                        />
                        <div className="shop-card-price-container">
                            <span className={"shop-card-discount-price"}>
                                {productPrice(item.discountPrice ? item.discountPrice : item.price, currencyValue)}
                                <span style={{ textTransform: "lowercase" }}>{currencyName}</span>
                            </span>

                            {item.discountPrice ? (
                                <span className="shop-card-price">
                                    {productPrice(item.price, currencyValue)}
                                    {currencyName}
                                </span>
                            ) : null}
                        </div>
                    </div>
                </div>
            </NavLink>
        </React.Fragment>
    );
};

ProductCard.propTypes = {
    product: PropTypes.object.isRequired,
    currency: PropTypes.object.isRequired,
};

export default ProductCard;
