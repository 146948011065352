import React from "react";
import Menu from "../UI/Menu/Menu";
import MenuComponent from "../Menus/MenuComponent";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import "./Menu.css";

const MainMenu = (props) => {
    return (
        <div>
            <nav className="navbar navbar-expand-md navbar-light bg-light" style={{ boxShadow: "0 2px 2px -2px gray" }}>
                <div className="container">
                    <span className="navbar-brand">
                        <NavLink to={"/"} exact>
                            Nội thất Vương Gia Phú
                        </NavLink>
                    </span>
                    <button className="navbar-toggler" onClick={props.toggleSideBar}>
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div>
                        <NavLink className={"nav-link"} to={"/cart"} exact>
                            <i className="bi bi-cart"></i>
                            <span className="badge badge-light rounded-pill">{props.cartItemNumber}</span>
                        </NavLink>
                    </div>
                </div>
            </nav>
            <nav className="main-navbar navbar navbar-expand-md navbar-dark bg-dark">
                <div className="collapse navbar-collapse d-flex justify-content-center">
                    <Menu menuClasses="navbar-nav ">
                        <MenuComponent cartCount={props.cartItemNumber} />
                    </Menu>
                </div>
            </nav>
        </div>
    );
};

MainMenu.propTypes = {
    toggleSideBar: PropTypes.func.isRequired,
    cartItemNumber: PropTypes.number.isRequired,
};

export default MainMenu;
