export const VISIBILITY_FILTERS = {
    PHONG_TAM: "thiet-bi-phong-tam",
    BON_CAU: "boncau",
    BON_TIEU: "bon-tieu",
    SEN_TAM: "sen-tam",
    PHU_KIEN_PHONG_TAM: "phu-kien",
    LAVABO: "lavabo",
    TU_LAVABO: "tu-lavabo",
    BAN_DA_LAVABO: "bandalavabo",
    VOI_LAVABO: "voi-lavabo",
    BON_TAM: "bon-tam",
    NHA_BEP: "thiet-bi-nha-bep",
    MAY_HUT_MUI: "may-hut-mui",
    BEP_GA: "bep-ga",
    BEP_DIEN: "bep-dien",
    CHAU_RUA_CHEN: "chau-rua-chen",
    VOI_RUA_CHEN: "voi-rua-chen",
    DEN_TRANG_TRI: "Đèn trang trí",
    THIET_BI_DIEN: "Thiết bị điện",
    SALE: "sale",
    HIGHLIGHTED: "Bán chạy",
    SIMILAR: "Sản phẩm tương tự",
};

export const convertCategoryLinkToText = (link) => {
    const categories = {
        thietbiphongtam: "Thiết bị phòng tắm",
        "sen-tam": "Sen tắm",
        lavabo: "Lavabo",
        "tu-lavabo": "Tủ lavabo",
        bandalavabo: "Bàn đá lavabo",
        boncau: "Bồn cầu",
        "voi-lavabo": "Vòi lavabo",
        "bon-tieu": "Bồn tiểu",
        "phu-kien": "Phụ kiện phòng tắm",
        "bon-tam": "Bồn tắm",
        "thiet-bi-nha-bep": "Thiết bị nhà bếp",
        "chau-rua-chen": "Chậu rửa chén",
        "bep-ga": "Bếp ga",
        "bep-dien": "Bếp điện",
        "may-hut-mui": "Máy hút mùi",
        "voi-rua-chen": "Vòi rửa chén",
        "den-trang-tri": "Đèn trang trí",
        "den-chum": "Đèn chùm",
        "den-op-tran": "Đèn ốp trần",
        "den-tha": "Đèn thả",
        "den-trang-tri": "Đèn trang trí",
        "den-khac": "Đèn khác",
    };
    return categories[link];
};

export const CATEGORIES_TYPES = {
    TBVS: [
        {
            code: "boncau",
            name: "Bồn cầu",
            imgURL: "https://firebasestorage.googleapis.com/v0/b/vuonggiaphu.appspot.com/o/menu%2Fcat-boncau-373x144.png?alt=media",
            link: "category/thiet-bi-phong-tam/boncau",
        },
        {
            name: "Bồn cầu điện tử",
            code: "bon-cau-thong-minh",
            imgURL: "https://firebasestorage.googleapis.com/v0/b/vuonggiaphu.appspot.com/o/menu%2Fcat-boncaudientu-373x144.png?alt=media",
            link: "category/thiet-bi-phong-tam/bon-cau-thong-minh",
        },
        {
            name: "Chậu Lavabo",
            code: "chau-rua-lavabo",
            imgURL: "https://firebasestorage.googleapis.com/v0/b/vuonggiaphu.appspot.com/o/menu%2Fcat-lavabo-373x144.png?alt=media",
            link: "category/thiet-bi-phong-tam/lavabo",
        },
        {
            name: "Vòi chậu",
            code: "voi-rua-lavabo",
            imgURL: "https://firebasestorage.googleapis.com/v0/b/vuonggiaphu.appspot.com/o/menu%2Fcat-voichau-373x144.png?alt=media",
            link: "category/thiet-bi-phong-tam/voi-lavabo",
        },
        {
            name: "Vòi sen",
            code: "voi-sen",
            imgURL: "https://firebasestorage.googleapis.com/v0/b/vuonggiaphu.appspot.com/o/menu%2Fcat-voisen-373x144.png?alt=media",
            link: "category/thiet-bi-phong-tam/voi-sen",
        },
        {
            name: "Sen cây",
            code: "sen-cay",
            imgURL: "https://firebasestorage.googleapis.com/v0/b/vuonggiaphu.appspot.com/o/menu%2Fcat-sencay-373x144.png?alt=media",
            link: "category/thiet-bi-phong-tam/sen-tam",
        },
        {
            name: "Bồn tắm",
            code: "bon-tam",
            imgURL: "https://firebasestorage.googleapis.com/v0/b/vuonggiaphu.appspot.com/o/menu%2Fcat-bontam-373x144.png?alt=media",
            link: "category/thiet-bi-phong-tam/bon-tam",
        },
        {
            name: "Bồn tiểu",
            code: "bon-tieu",
            imgURL: "https://firebasestorage.googleapis.com/v0/b/vuonggiaphu.appspot.com/o/menu%2Fcat-bontieu-373x144.png?alt=media",
            link: "category/thiet-bi-phong-tam/bon-tieu",
        },
        {
            name: "Phụ kiện",
            code: "phu-kien-phong-tam",
            imgURL: "https://firebasestorage.googleapis.com/v0/b/vuonggiaphu.appspot.com/o/menu%2Fcat-phukien-373x144.png?alt=media",
            link: "category/thiet-bi-phong-tam/phu-kien",
        },
    ],
    BRAND_TBVS: [
        {
            name: "TOTO",
            code: "toto",
            imgURL: "https://firebasestorage.googleapis.com/v0/b/vuonggiaphu.appspot.com/o/menu%2Flogo-toto-212x116.png?alt=media",
            link: "thuong-hieu/toto",
        },
        {
            name: "INAX",
            code: "inax",
            imgURL: "https://firebasestorage.googleapis.com/v0/b/vuonggiaphu.appspot.com/o/menu%2Flogo-inax-212x116.png?alt=media",
            link: "thuong-hieu/inax",
        },
        {
            name: "VIGLACERA",
            code: "viglacera",
            imgURL: "https://firebasestorage.googleapis.com/v0/b/vuonggiaphu.appspot.com/o/menu%2Flogo-viglacera-212x116.png?alt=media",
            link: "thuong-hieu/viglacera",
        },
        {
            name: "AMERICAN STANDARD",
            code: "american-standard",
            imgURL: "https://firebasestorage.googleapis.com/v0/b/vuonggiaphu.appspot.com/o/menu%2Flogo-american-standard-212x116.png?alt=media",
            link: "thuong-hieu/american-standard",
        },
        {
            name: "ATTAX",
            code: "attax",
            imgURL: "https://firebasestorage.googleapis.com/v0/b/vuonggiaphu.appspot.com/o/menu%2Flogo-american-standard-212x116.png?alt=media",
            link: "thuong-hieu/attax",
        },
        {
            name: "HAPHAKO",
            code: "haphako",
            imgURL: "https://firebasestorage.googleapis.com/v0/b/vuonggiaphu.appspot.com/o/menu%2Flogo-american-standard-212x116.png?alt=media",
            link: "thuong-hieu/haphako",
        },
    ],
    TBBEP: [
        {
            name: "Bếp Điện Từ",
            code: "bep-dien-tu",
            imgURL: "https://firebasestorage.googleapis.com/v0/b/vuonggiaphu.appspot.com/o/menu%2Fcat-bepdientu-373x144.png?alt=media",
            link: "category/thiet-bi-bep/bep-dien",
        },
        {
            name: "Bếp Gas",
            code: "bep-ga",
            imgURL: "https://firebasestorage.googleapis.com/v0/b/vuonggiaphu.appspot.com/o/menu%2Fcat-bepgas-373x144.png?alt=media",
            link: "category/thiet-bi-bep/bep-ga",
        },
        {
            name: "Máy Hút Mùi",
            code: "may-hut-mui",
            imgURL: "https://firebasestorage.googleapis.com/v0/b/vuonggiaphu.appspot.com/o/menu%2Fcat-mayhutmui-373x144.png?alt=media",
            link: "category/thiet-bi-bep/may-hut-mui",
        },
        {
            name: "Chậu rửa chén bát",
            code: "chau-rua-chen",
            imgURL: "https://firebasestorage.googleapis.com/v0/b/vuonggiaphu.appspot.com/o/menu%2Fcat-chauruachen-373x144.png?alt=media",
            link: "category/thiet-bi-bep/chau-rua-chen",
        },
        {
            name: "Vòi rửa chén",
            code: "voi-rua-chen",
            imgURL: "https://firebasestorage.googleapis.com/v0/b/vuonggiaphu.appspot.com/o/menu%2Fcat-voiruachen-373x144.png?alt=media",
            link: "category/thiet-bi-bep/voi-rua-chen",
        },
    ],
    BRAND_TBBEP: [
        {
            name: "ATTAX",
            code: "attax",
            imgURL: "https://firebasestorage.googleapis.com/v0/b/vuonggiaphu.appspot.com/o/menu%2Flogo-american-standard-212x116.png?alt=media",
            link: "thuong-hieu/attax",
        },
        {
            name: "HAPHAKO",
            code: "haphako",
            imgURL: "https://firebasestorage.googleapis.com/v0/b/vuonggiaphu.appspot.com/o/menu%2Flogo-american-standard-212x116.png?alt=media",
            link: "thuong-hieu/haphako",
        },
        {
            name: "EFFEGI",
            code: "effegi",
            imgURL: "https://firebasestorage.googleapis.com/v0/b/vuonggiaphu.appspot.com/o/menu%2Flogo-american-standard-212x116.png?alt=media",
            link: "thuong-hieu/effegi",
        },
        {
            name: "BOSCH",
            code: "bosch",
            imgURL: "https://firebasestorage.googleapis.com/v0/b/vuonggiaphu.appspot.com/o/menu%2Flogo-american-standard-212x116.png?alt=media",
            link: "thuong-hieu/bosch",
        },
        {
            name: "MALLOCA",
            code: "malloca",
            imgURL: "https://firebasestorage.googleapis.com/v0/b/vuonggiaphu.appspot.com/o/menu%2Flogo-american-standard-212x116.png?alt=media",
            link: "thuong-hieu/malloca",
        },
    ],
};
