import {initializeApp} from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDgb04d4cZHdj-uA-0JthlbAY90l7RnHmo",
  authDomain: "vuonggiaphu.firebaseapp.com",
  databaseURL: "https://vuonggiaphu-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "vuonggiaphu",
  storageBucket: "vuonggiaphu.appspot.com",
  messagingSenderId: "199409496811",
  appId: "1:199409496811:web:5788479d2ddd504d53c4ae",
  measurementId: "G-R0WKY5GJH7"
};

export const initFirebaseApp = () => {
    initializeApp(firebaseConfig);
};