import React from "react";
const img1 = require(`../../../assets/images/vgp-banner-1.jpg`);
const img2 = require(`../../../assets/images/vgp-banner-2.jpg`);
const img3 = require(`../../../assets/images/vgp-banner-3.jpg`);
const img4 = require(`../../../assets/images/1.jpg`);
const img5 = require(`../../../assets/images/2.jpg`);

const images = [img1, img2, img3, img4, img5];
const Carousel = (props) => {
    return (
        <div id="carouselHome" className="carousel slide w-100" data-ride="carousel">
            <ol className="carousel-indicators">
                <li data-target="#carouselHome" data-slide-to="0" className="active"></li>
                <li data-target="#carouselHome" data-slide-to="1"></li>
                <li data-target="#carouselHome" data-slide-to="2"></li>
            </ol>
            <div className="carousel-inner h-100">
                {images.map((img, index) => {
                    return (
                        <div key={index} className={`carousel-item h-100 ${index === 0 ? "active" : ""}`}>
                            <img className="d-block w-100 h-100" src={img} alt={`Slide ${index + 1}`} />
                        </div>
                    );
                })}
            </div>
            <a className="carousel-control-prev" href="#carouselHome" data-slide="prev">
                <span className="carousel-control-prev-icon"></span>
            </a>
            <a className="carousel-control-next" href="#carouselHome" data-slide="next">
                <span className="carousel-control-next-icon"></span>
            </a>
        </div>
    );
};

Carousel.propTypes = {};

export default Carousel;
