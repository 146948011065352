export const currencyToUse = (usedCurrency) => {
    let currencyKeys = Object.keys(usedCurrency);
    return {
        name: usedCurrency[currencyKeys[1]],
        value: usedCurrency[currencyKeys[0]],
    };
};

export const productPrice = (price, value) => {
    return Math.round(price * value).toLocaleString();
};

export const productDiscountPrice = (price, discountPrice) => {
    const percent = Math.round(((price - discountPrice) * 100) / price);
    return percent > 0 ? `-${percent}%` : "";
};
