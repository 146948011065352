import React from "react";
import "./ShopFeatures.css";

const ShopFeatures = () => {
    return (
        <div className="clearfix container mt-4">
            <section className="shops-features">
                <div className="shops-features__cell">
                    <article className="shops-features__item">
                        <div className="shops-features__item__icon">
                            <img
                                src={require("../../assets/icons/icon-shop-features-1.png")}
                                data-src="catalog/view/theme/default/image/icon-shop-features-1.png"
                                alt="Chinh sach 1"
                                title="Chinh sach 1"
                                className="lozad loaded"
                                width="36"
                                height="37"
                                data-loaded="true"
                            />
                        </div>
                        <div className="shops-features__item__content">
                            <h3>Giao hàng miễn phí</h3>
                            <p>Miễn phí giao hàng với đơn hàng trên 5.000.000đ</p>
                        </div>
                    </article>
                </div>
                <div className="shops-features__cell">
                    <article className="shops-features__item">
                        <div className="shops-features__item__icon">
                            <img
                                src={require("../../assets/icons/icon-shop-features-2.png")}
                                data-src="catalog/view/theme/default/image/icon-shop-features-2.png"
                                alt="Chinh sach 2"
                                title="Chinh sach 2"
                                className="lozad loaded"
                                width="26"
                                height="37"
                                data-loaded="true"
                            />
                        </div>
                        <div className="shops-features__item__content">
                            <h3>Cam kết chính hãng</h3>
                            <p>Sản phẩm chính hãng và được bảo hành của hãng</p>
                        </div>
                    </article>
                </div>
                <div className="shops-features__cell">
                    <article className="shops-features__item">
                        <div className="shops-features__item__icon">
                            <img
                                src={require("../../assets/icons/icon-shop-features-3.png")}
                                data-src="catalog/view/theme/default/image/icon-shop-features-3.png"
                                alt="Chinh sach 3"
                                title="Chinh sach 3"
                                className="lozad loaded"
                                width="30"
                                height="37"
                                data-loaded="true"
                            />
                        </div>
                        <div className="shops-features__item__content">
                            <h3>Chiết khấu hấp dẫn</h3>
                            <p>Luôn có mức chiết khấu tốt nhất cho khách hàng</p>
                        </div>
                    </article>
                </div>
                <div className="shops-features__cell">
                    <article className="shops-features__item">
                        <div className="shops-features__item__icon">
                            <img
                                src={require("../../assets/icons/icon-shop-features-4.png")}
                                data-src="catalog/view/theme/default/image/icon-shop-features-4.png"
                                alt="Chinh sach 4"
                                title="Chinh sach 4"
                                className="lozad loaded"
                                width="31"
                                height="37"
                                data-loaded="true"
                            />
                        </div>
                        <div className="shops-features__item__content">
                            <h3>Thanh toán đơn giản</h3>
                            <p>Có nhiều phương thức để quý khách lựa chọn</p>
                        </div>
                    </article>
                </div>
            </section>
        </div>
    );
};

export default ShopFeatures;
