import React from 'react';
import FooterLinks from './components/FooterLinks';
import GitLinks from './components/GitLink';
import './Footer.css';

const Index = () => {
    return (
        <React.Fragment>
            <FooterLinks />
            <GitLinks />
        </React.Fragment>
    );
};

export default Index;
