export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const CLEAR_CART = "CLEAR_CART";
export const UPDATE_CART_PRODUCT_COUNT = "UPDATE_CART_PRODUCT_COUNT";
export const CONFIRM_ORDER_SUCCESS = "CONFIRM_ORDER_SUCCESS";
export const CONFIRM_ORDER_FAILURE = "CONFIRM_ORDER_FAILURE";
export const RESET_ORDER_SUCCESS = "RESET_ORDER_SUCCESS";
export const CLOSE_MAX_PRODUCT_MODAL = "CLOSE_MAX_PRODUCT_MODAL";
export const TOGGLE_SIDE_BAR = "TOGGLE_SIDE_BAR";
export const SET_PROMO_CODE = "SET_PROMO_CODE";
export const CHANGE_CURRENCY = "CHANGE_CURRENCY";
export const TOOLE_ITEM_IN_WISHLIST = "TOOLE_ITEM_IN_WISHLIST";
export const SET_PRODUCT_PRICE_FILTER = "SET_PRODUCT_PRICE_FILTER";
export const SET_ALL_PRODUCTS = "SET_ALL_PRODUCTS";
