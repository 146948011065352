import { VISIBILITY_FILTERS } from "../static/constants";

export const getProducts = (store) => store.products;
export const getProductPriceFilter = (store) => store.priceFilter;
export const getWishlist = (store) => store.wishlist;

export const getProductsByFilter = (store, visibilityFilter, count = null, query = null) => {
    const allProducts = getProducts(store);
    const filterPrices = getProductPriceFilter(store);
    switch (visibilityFilter) {
        case VISIBILITY_FILTERS.ALL:
            return allProducts.slice(0, 20);
        case VISIBILITY_FILTERS.SEN_TAM:
        case VISIBILITY_FILTERS.BON_CAU:
        case VISIBILITY_FILTERS.LAVABO:
        case VISIBILITY_FILTERS.TU_LAVABO:
        case VISIBILITY_FILTERS.VOI_LAVABO:
        case VISIBILITY_FILTERS.BON_TIEU:
        case VISIBILITY_FILTERS.BAN_DA_LAVABO:
        case VISIBILITY_FILTERS.BON_TAM:
        case VISIBILITY_FILTERS.PHU_KIEN_PHONG_TAM:
        case VISIBILITY_FILTERS.MAY_HUT_MUI:
        case VISIBILITY_FILTERS.BEP_DIEN:
        case VISIBILITY_FILTERS.BEP_GA:
        case VISIBILITY_FILTERS.CHAU_RUA_CHEN:
        case VISIBILITY_FILTERS.VOI_RUA_CHEN:
            console.log(visibilityFilter);
            return allProducts.filter((product) => product.subcategory === visibilityFilter && product.price < filterPrices.pricerange);

        case VISIBILITY_FILTERS.PHONG_TAM:
        case VISIBILITY_FILTERS.NHA_BEP:
        case VISIBILITY_FILTERS.DEN_TRANG_TRI:
        case VISIBILITY_FILTERS.THIET_BI_DIEN:
            return allProducts.filter((product) => product.category === visibilityFilter && product.price < filterPrices.pricerange);
        case VISIBILITY_FILTERS.SALE:
            if (count) {
                return allProducts.filter((product, index) => {
                    if (product.sale === true && index < 10) {
                        return true;
                    }
                    return false;
                });
            } else {
                return allProducts.filter((product) => product.sale === true && product.price < filterPrices.pricerange);
            }
        case VISIBILITY_FILTERS.HIGHLIGHTED:
            if (count) {
                return allProducts.filter((product, index) => {
                    if (product.highlighted === true && index < 10) {
                        return true;
                    }
                    return false;
                });
            } else {
                return allProducts.filter((product) => product.highlighted === true && product.price < filterPrices.pricerange);
            }
        case VISIBILITY_FILTERS.SIMILAR:
            if (count) {
                return allProducts.filter((product, index) => {
                    if (product.subcategory === query && index < 10) {
                        return true;
                    }
                    return false;
                });
            } else {
                return allProducts.filter((product) => product.subcategory === query && product.price < filterPrices.pricerange);
            }
        default:
            return [];
    }
};

export const getUsedCurrency = (store) => store.usedCurrency;
