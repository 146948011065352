import React from "react";

const GitLink = () => {
  return (
    <div className=" shop-footer text-center p-3">
      Copyright by
      <a
        href="http://collinsnick.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        {` Javier Ta `}
      </a>
      @2020
    </div>
  );
};

export default GitLink;
