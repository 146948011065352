import React, { useRef } from "react";
import "./ProductImage.css";

const ZoomImages = ({ product }) => {
    const imgRef = useRef();

    const handleMouseMove = (e) => {
        const { left, top, width, height } = e.target.getBoundingClientRect();
        const x = ((e.pageX - left) / width) * 100;
        const y = ((e.pageY - top) / height) * 100;
        imgRef.current.style.transformOrigin = `${x}% ${y}%`;
    };

    return (
        <div className="zoom-image-container">
            <img ref={imgRef} src={product.imageUrl} alt={product.productCode} className="zoom-image" onMouseMove={handleMouseMove} />
        </div>
    );
};
export default ZoomImages;
