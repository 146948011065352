export default function formValidator(identifier, value) {
    let isValid = true;
    let errMsg = "";

    switch (identifier) {
        case "firstName":
        case "address":
            if (value.trim() === "" && isValid) {
                isValid = false;
                errMsg = "Không được để trống";
            }
            break;
        case "phoneNumber":
            const pattern = new RegExp(/^[0-9]*$/);
            if (value.trim() === "" && isValid) {
                isValid = false;
                errMsg = "Số điện thoại không được để trống";
            } else if (value.length > 11 && isValid) {
                isValid = false;
                errMsg = "Số điện thoại quá dài";
            }
            // number only
            else if (!pattern.test(value)) {
                isValid = false;
                errMsg = "Số điện thoại chỉ chứa số";
            }
            break;

        case "email":
            if (value.trim() === "" && isValid) {
                isValid = false;
                errMsg = "Email Address Must have a value";
            } else {
                const pattern = new RegExp(
                    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
                );
                if (!pattern.test(value)) {
                    isValid = false;
                    errMsg = "Please enter a valid email address";
                }
            }
            break;

        default:
    }

    return { isValid: isValid, errorsMsg: errMsg };
}
