import React from 'react';
import PropTypes from 'prop-types';
import BreadCrumbs from '../components/UI/BreadCrumbs/BreadCrumbs';

const SecondaryLayout = (props) => {
    return (
        <div className={`container ${props.breadCrumbs ? 'py-2' : 'py-4'}`}>
            {props.breadCrumbs ? (
                <div className="row">
                    <div className="col-sm-12">
                        <BreadCrumbs breadCrumbLinks={props.breadCrumbs} />
                    </div>
                </div>
            ) : null}
            <div className="row">
                <div className="col-md-12 col-lg-12">
                    <div className="row">{props.children}</div>
                </div>
            </div>
        </div>
    );
};

SecondaryLayout.prototypes = {
    breadCrumbs: PropTypes.array,
};

export default SecondaryLayout;
