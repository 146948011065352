import React from 'react';

const ProductFeatures = (props) => {
    return (
        <React.Fragment>
            <span className="shop-card-product-features" title="Duka Aproved">
                {props.product.description}
            </span>
        </React.Fragment>
    );
};

export default ProductFeatures;
