import { getFirestore, collection, getDocs, setDoc, doc} from "firebase/firestore/lite";
import "firebase/firestore";
import { initFirebaseApp } from "./firebase";


export const getAllProductsFromFirestore = async () => {
    initFirebaseApp();
    const db = getFirestore();
    const productsCol = collection(db, "products");
    const productsSnapshot = await getDocs(productsCol);
    const productsList = productsSnapshot.docs.map(doc => doc.data());


    // const products = [
    //     { productCode: "AC-969VN", name: "Bồn cầu liền khối", price: 6600000, discountPrice: 0, description: "", imageUrl: "https://firebasestorage.googleapis.com/v0/b/vuonggiaphu.appspot.com/o/ac969.png?alt=media&token=c8670673-73e1-4682-93b4-6bc8d978b19a", quantity: 0, category: "", slug: '', subcategory: '', highlighted: true, sale: true, brand: 'Inax', ratings: {star_ratings: 4.8,votes: 350} },
    //     { productCode: "AC-959VAN", name: "Bồn cầu liền khối", price: 6600000, discountPrice: 0, description: "", imageUrl: "https://firebasestorage.googleapis.com/v0/b/vuonggiaphu.appspot.com/o/ac959.png?alt=media&token=97a8f17b-4e99-48c8-be89-21b637986bc2", quantity: 0, category: "", slug: '', subcategory: '', highlighted: true, sale: true, brand: 'Inax', ratings: {star_ratings: 4.8,votes: 350} } },
    //     { productCode: "AC-939VN", name: "Bồn cầu liền khối", price: 6600000, discountPrice: 0, description: "", imageUrl: "https://firebasestorage.googleapis.com/v0/b/vuonggiaphu.appspot.com/o/ac939.png?alt=media&token=d38f0518-55bc-4357-80ed-24099c48b700", quantity: 0, category: "", slug: '', subcategory: '', highlighted: true, sale: true, brand: 'Inax', ratings: {star_ratings: 4.8,votes: 350} } },
    //     { productCode: "AC-900", name: "Bồn cầu liền khối", price: 6600000, discountPrice: 0, description: "", imageUrl: "https://firebasestorage.googleapis.com/v0/b/vuonggiaphu.appspot.com/o/ac900.png?alt=media&token=a90f7c54-2ec2-4755-b8be-bee87aba8afb", quantity: 0, category: "", slug: '', subcategory: '', highlighted: true, sale: true, brand: 'Inax', ratings: {star_ratings: 4.8,votes: 350} } },
    //     { productCode: "AC-902", name: "Bồn cầu liền khối", price: 6600000, discountPrice: 0, description: "", imageUrl: "https://firebasestorage.googleapis.com/v0/b/vuonggiaphu.appspot.com/o/ac902.png?alt=media&token=22960f7c-dfa4-4115-b35f-702ee236fd99", quantity: 0, category: "", slug: '', subcategory: '', highlighted: true, sale: true, brand: 'Inax', ratings: {star_ratings: 4.8,votes: 350} } },
    //     { productCode: "AC-1032VN", name: "Bồn cầu liền khối", price: 6600000, discountPrice: 0, description: "", imageUrl: "", quantity: 0, category: "", slug: '', subcategory: '', highlighted: true, sale: true, brand: 'Inax', ratings: {star_ratings: 4.8,votes: 350} } },
    //     { productCode: "AC-710VAN", name: "Bồn cầu 2 khối", price: 6600000, discountPrice: 0, description: "", imageUrl: "", quantity: 0, category: "", slug: '', subcategory: '', highlighted: true, sale: true, brand: 'Inax', ratings: {star_ratings: 4.8,votes: 350} } },
    //     { productCode: "OTP-01", name: "Bồn cầu liền khối", price: 6600000, discountPrice: 0, description: "", imageUrl: "", quantity: 0, category: "", slug: '', subcategory: '', highlighted: true, sale: true, brand: 'Attax', ratings: {star_ratings: 4.8,votes: 350} } },
    //     { productCode: "OTP-03", name: "Bồn cầu liền khối", price: 6600000, discountPrice: 0, description: "", imageUrl: "", quantity: 0, category: "", slug: '', subcategory: '', highlighted: true, sale: true, brand: 'Attax', ratings: {star_ratings: 4.8,votes: 350} } },
    //     { productCode: "OTP-05", name: "Bồn cầu liền khối", price: 6600000, discountPrice: 0, description: "", imageUrl: "", quantity: 0, category: "", slug: '', subcategory: '', highlighted: true, sale: true, brand: 'Attax', ratings: {star_ratings: 4.8,votes: 350} } },
    //     { productCode: "OTP-11", name: "Bồn cầu liền khối", price: 6600000, discountPrice: 0, description: "", imageUrl: "", quantity: 0, category: "", slug: '', subcategory: '', highlighted: true, sale: true, brand: 'Attax', ratings: {star_ratings: 4.8,votes: 350} } },
    //     { productCode: "OTP-13", name: "Bồn cầu liền khối", price: 6600000, discountPrice: 0, description: "", imageUrl: "", quantity: 0, category: "", slug: '', subcategory: '', highlighted: true, sale: true, brand: 'Attax', ratings: {star_ratings: 4.8,votes: 350} } },
    //   ];
      
    //   products.forEach(async (product) => {
    //     await addProductToFirestore(product);
    //   });
    return productsList;
}

export const addProductToFirestore = async (product) => {
    initFirebaseApp();
    const db = getFirestore();
    const productsCol = collection(db, "products");
    const productDoc = doc(productsCol, product.productCode);
    await setDoc(productDoc, product);
};