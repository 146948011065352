import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './MenuItem.css';

const MenuItem = (props) => {
    return props.elements ? (
        <li className={'nav-item dropdown'}>
            {/* <a
                href="#"
                className="nav-link dropdown-toggle"
                role="button"
                data-toggle="dropdown"
                aria-expanded="false">
                {props.children}
            </a> */}
            <div
                className="nav-link dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                aria-expanded="false">
                {props.children}
            </div>
            <div className="dropdown-menu">
                {props.elements.map((el) => {
                    return (
                        <NavLink
                            className={'dropdown-item nav-link'}
                            to={el.linkTo}
                            exact
                            key={el.title}>
                            {el.title}
                        </NavLink>
                    );
                })}
            </div>
        </li>
    ) : (
        <li className={'nav-item'}>
            <NavLink className={'nav-link'} to={props.linkTo} exact>
                {props.children}
            </NavLink>
        </li>
    );
};

MenuItem.propTypes = {
    navItemClasses: PropTypes.string,
    navLinkClasses: PropTypes.string,
    linkTo: PropTypes.string
};

export default MenuItem;
