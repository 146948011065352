import React, { Component } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { closeMaxProductModal, setAllProducts, toogleSideBar } from "./store/actions";
import MainLayout from "./Layouts/MainLayout";
import * as Maincontainers from "./views";
import "./App.css";
import { getAllProductsFromFirestore } from "./firebase/products";
import Loader from "./components/Loader/Index";

class App extends Component {
    constructor() {
        super();
        this.state = {
            loading: true,
        };
    }

    async componentDidMount() {
        const products = await getAllProductsFromFirestore();
        const displayProducts = products.filter((product) => product.isDisplay);
        this.props.setAllProducts(displayProducts);
        this.setState({ loading: false });
    }

    render() {
        return (
            <div className="App">
                {this.state.loading ? (
                    <Loader />
                ) : (
                    <MainLayout
                        storeCartCount={this.props.storeCartItemsCount}
                        showModal={this.props.showModalProp}
                        closeModalProp={this.props.closeModalProp}
                        modalMessage={this.props.modalMessageProp}
                        showSideBar={this.props.showSideNavigationProp}
                        toggleSideBar={this.props.toggleSideBarProp}
                    >
                        <Switch>
                            <Route path={"/"} exact component={Maincontainers.HomePage} />
                            <Route path={"/all"} exact component={Maincontainers.AllPage} />
                            <Route path={"/category/:category/:subcategory"} component={Maincontainers.ProductCategoriesPage} />

                            <Route path={"/catalogue/:brand"} component={Maincontainers.CataloguePage} />
                            <Route path={"/sale"} component={Maincontainers.SalesPage} />
                            <Route path={"/cart"} component={Maincontainers.CartPage} />
                            <Route path={"/checkout"} component={Maincontainers.CheckoutPage} />
                            <Route
                                path={"/:productSlug"}
                                render={(props) => <Maincontainers.ProductDetailsPage key={props.match.params.productSlug} {...props} />}
                            />

                            <Redirect to={"/"} />
                        </Switch>
                    </MainLayout>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        storeCartItemsCount: state.cartTotal,
        showModalProp: state.productMaxShowModal,
        modalMessageProp: state.modalMessage,
        showSideNavigationProp: state.showSideNavigation,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        closeModalProp: () => dispatch(closeMaxProductModal()),
        toggleSideBarProp: () => dispatch(toogleSideBar()),
        setAllProducts: (products) => dispatch(setAllProducts(products)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
