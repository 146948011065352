import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "./catalog.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.js", import.meta.url).toString();
const CataloguePage = (props) => {
    const [numPages, setNumPages] = useState(null);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const {
        match: { params },
    } = props;
    const getFile = () => {
        switch (params.brand) {
            case "haphako":
                return "/pdfs/HAPHAKO.pdf";
            case "attax":
                return "/pdfs/ATTAX.pdf";
            case "inax":
                return "/pdfs/INAX.pdf";
            // case "toto":
            //     return "/pdfs/TOTO_2023.pdf";
            default:
                return "/pdfs/ATTAX.pdf";
        }
    };
    // return <div>{params.brand}</div>;
    return (
        <Document file={getFile()} onLoadSuccess={onDocumentLoadSuccess}>
            {/* <Page pageNumber={1}/> */}
            {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
        </Document>
    );
};

export default CataloguePage;
