import React from "react";
import { connect } from "react-redux";
// import { currencyToUse } from '../../../../Utility/currency';
import "./HomeSale.css";
import ProductCard from "../../../../components/ProductCard/Index";

const HomeSale = (props) => {
    // let currencyKeys = currencyToUse(props.usedCurrencyProp);
    let products = props.products.map((product, index) => {
        return <ProductCard key={`${product.id}-${index}`} product={product} currency={props.usedCurrencyProp} />;
    });

    return (
        <div className="container products-section mb-4">
            <div className="product-section-title">
                <div class="title-section">
                    <h3>{props.title}</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 col-lg-12">
                    <div className="products-container row">{products}</div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        usedCurrencyProp: state.usedCurrency,
    };
};

export default connect(mapStateToProps)(HomeSale);
