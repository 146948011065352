import React, { Component } from "react";
import { connect } from "react-redux";
import { addToCart } from "../../store/actions";
import BreadCrumbs from "../../components/UI/BreadCrumbs/BreadCrumbs";
import Ratings from "../../components/Ratings/Ratings";
import AddToWishList from "../../components/AddToWishlist/AddToWishlist";
import ProductFeatures from "../../components/ProductCard/ProductFeatures";
import { currencyToUse, productDiscountPrice } from "../../Utility/currency";
import HomeSale from "../Home/components/HomeSale/HomeSale";
import "./ProductDetails.css";
import { getProductsByFilter } from "../../store/selectors";
import { VISIBILITY_FILTERS, convertCategoryLinkToText } from "../../static/constants";
import ZoomImages from "../../components/ProductDetailImages/ProductDetailImages";
import ProductCard from "../../components/ProductCard/Index";

class ProductDetails extends Component {
    state = {
        productDetails: {
            id: null,
            size: "",
            quantity: 1,
        },
    };

    componentDidMount() {
        if (this.props.productProp) {
            this.setState((prevState) => ({
                productDetails: {
                    ...prevState.productDetails,
                    id: this.props.productProp.productCode,
                    quantity: 1,
                },
            }));
        }
    }

    product = this.props.productProp;
    currencyKeys = currencyToUse(this.props.usedCurrencyProp);

    truncateProductName() {
        const productName = this.product.name;
        return productName.length > 45 ? `${productName.substring(0, 45)}...` : productName;
    }

    disableAddToCartButton() {
        let prodDetails = this.state.productDetails;
        let generalValidations = !prodDetails.id || !prodDetails.quantity || prodDetails.quantity < 1 || prodDetails.quantity > this.product.quantity;
        if (!this.product.sizes) {
            return generalValidations;
        }
        return generalValidations || !prodDetails.size;
    }

    handleAdditionSubtraction(action) {
        let stateData = this.state.productDetails;
        if (action === "subtract" && stateData.quantity < 1) {
            return;
        }

        let quantity = parseInt(stateData.quantity);
        let newValue = action === "subtract" ? quantity - 1 : quantity + 1;

        this.setState((prevState) => ({
            productDetails: {
                ...prevState.productDetails,
                quantity: newValue,
            },
        }));
    }

    handleInputChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = target.name;

        if (name === "quantity") {
            if (!value.match(/^[0-9]+$/)) {
                value = 1;
            }
        }

        this.setState({
            productDetails: { ...this.state.productDetails, [name]: value },
        });
    };

    handleAddToCart = () => {
        this.props.addProductToCartProp(this.state.productDetails);
    };

    renderNotFoundProduct = () => {
        return (
            <div className="container py-4">
                <div className={"shop-div p-4 text-center w-100"}>
                    <h5>Không tìm thấy sản phẩm</h5>
                </div>
            </div>
        );
    };

    renderBreadCrumbs = () => {
        return (
            <BreadCrumbs
                breadCrumbLinks={[
                    // {
                    //     label: convertCategoryLinkToText(this.product.category),
                    //     to: `/category/${this.product.category}`,
                    // },
                    {
                        label: convertCategoryLinkToText(this.product.subcategory),
                        to: `/category/${this.product.category}/${this.product.subcategory}`,
                    },
                    {
                        label: this.truncateProductName(),
                        to: null,
                    },
                ]}
            />
        );
    };

    renderProductName = () => {
        return (
            <div className="product-title-container">
                <h3 className="product-title">{this.product.name}</h3>
                <AddToWishList productId={this.product.id} title={"add to wishlist"} className={"product-wishlist"} />
            </div>
        );
    };

    renderRating = () => {
        return (
            <div>
                <span>
                    <Ratings
                        ratings={this.product.ratings}
                        containerClassName={"product-rating"}
                        fullStarIcon={"full-star-icon"}
                        halfStarIcon={"half-star-icon"}
                        emptyStarIcon={"empty-star-icon"}
                    />
                </span>
            </div>
        );
    };

    // renderProductPrice = () => {
    //     return (
    //         <div className="product-price-container">
    //             {this.product.discount_price ? (
    //                 <span className="product-discount-price">
    //                     {productPrice(
    //                         this.product.discount_price,
    //                         this.currencyKeys.value
    //                     )}
    //                     {this.currencyKeys.name}
    //                 </span>
    //             ) : null}
    //             <span className="product-price">
    //                 {productPrice(this.product.price, this.currencyKeys.value)}
    //                 {this.currencyKeys.name}
    //             </span>

    //             {this.product.discount_price ? (
    //                 <span className="product-percentage-discount">
    //                     {productDiscountPrice(
    //                         this.product.price,
    //                         this.product.discount_price
    //                     )}
    //                 </span>
    //             ) : null}
    //         </div>
    //     );
    // };

    renderAddToCartBtn = () => {
        return (
            <div className="col-xs-12 col-sm-4">
                <button
                    type="button"
                    id="button-cart"
                    data-loading-text="Đang Xử lý..."
                    className="btn btn-secondary btn-lg btn-block"
                    disabled={this.disableAddToCartButton()}
                    onClick={this.handleAddToCart}
                >
                    <i className="fa fa-cart-plus" aria-hidden="true"></i> {this.product.quantity ? "Thêm vào giỏ" : "Hết hàng"}
                </button>
            </div>
        );
    };

    renderProductFeature = () => {
        return (
            <div className="product-features">
                <p className="product-features-title text-muted">Mô tả:</p>
                <div className="feature-fulfillmemt">
                    <ProductFeatures product={this.product} />
                </div>
            </div>
        );
    };

    renderProductColor = () => {
        return this.product.color ? (
            <div className="product-features">
                <p className="product-features-title text-muted">màu sắc:</p>
                <span
                    className="feature-text feature-color"
                    style={{
                        backgroundColor: this.product.color === "White" ? "black" : this.product.color,
                    }}
                >
                    {this.product.color}
                </span>
            </div>
        ) : null;
    };

    renderProductSize = () => {
        return this.product.sizes ? (
            <div className="product-features">
                <p className="product-features-title text-muted">kích thước:</p>
                <span className="feature-text">
                    <select
                        className="custom-select custom-select-sm"
                        name="size"
                        value={this.state.productDetails.size}
                        onChange={(event) => this.handleInputChange(event)}
                    >
                        <option value="" disabled>
                            chọn size
                        </option>
                        {this.product.sizes.map((size, index) => (
                            <option key={index} value={size}>
                                {size}
                            </option>
                        ))}
                    </select>
                </span>
            </div>
        ) : null;
    };

    renderProductQuantity = () => {
        return this.product.quantity ? (
            <div className="product-features">
                <p className="product-features-title text-muted">số lượng:</p>
                <div className="product-quantity">
                    <button type="button" className="btn btn-secondary" onClick={() => this.handleAdditionSubtraction("subtract")}>
                        -
                    </button>
                    <input
                        name="quantity"
                        type="text"
                        className="form-control"
                        placeholder="Qty"
                        value={this.state.productDetails.quantity}
                        onChange={(event) => this.handleInputChange(event)}
                    ></input>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => this.handleAdditionSubtraction("add")}
                        disabled={this.state.productDetails.quantity >= this.product.quantity}
                    >
                        +
                    </button>
                </div>
            </div>
        ) : null;
    };

    renderProductImage = () => {
        return <ZoomImages product={this.product} />;
    };

    PRODUCT_HASTAGS = {
        boncau: ["Bồn Cầu Vệ Sinh", "Bồn Cầu 1 Khối"],
        "bon-tam": ["Bồn tắm", "Bồn tắm massage", "Bồn tắm cao cấp", "Bồn tắm galaxy"],
        "sen-tam": ["Sen cây", "Sen tắm nóng lạnh", "Sen tắm cao cấp"],
        other: ["Thiết bị vệ sinh", "Bồn cầu", "Bồn tắm", "Sen tắm"],
    };

    renderProductHashtag = () => {
        const hastags = this.PRODUCT_HASTAGS[this.product.subcategory];
        return (
            <div className="product-detail-info__categories">
                {hastags &&
                    hastags.map((hashtag, index) => {
                        return <a href={`https://vuonggiaphu.com/category/thiet-bi-phong-tam/${this.product.subcategory}`}>{hashtag}</a>;
                    })}
            </div>
        );
    };

    renderTest = () => {
        return (
            <div className="row">
                <div id="content" className="col-md-12">
                    <div className="row">
                        <div className="col-md-6">{this.renderProductImage()}</div>
                        <div className="col-md-6 product-detail-info">
                            {this.renderProductHashtag()}
                            <h2 className="title-product hidden-xs">{this.product.name + " " + this.product.productCode}</h2>
                            <div class="manufacturers_img">
                                <p>
                                    <strong>{this.product.brand && this.product.brand.toUpperCase()}</strong>
                                </p>
                                {/* <a href="https://www.tdm.vn/thiet-bi-ve-sinh-inax">
                                    <img src="https://www.tdm.vn/image/cachewebp/catalog/logo/logo-inax-150x70.webp" alt="INAX" title="INAX" />
                                </a> */}
                            </div>
                            {this.renderProductDetailInfo()}
                            <div id="product">
                                <div className="product-detail-info__action form-group">
                                    <div className="button-group row">
                                        <div className="product-detail-info__action__quantity col-xs-12 col-sm-3">
                                            <label className="control-label" htmlFor="input-quantity">
                                                Số lượng
                                            </label>
                                            <input
                                                type="text"
                                                name="quantity"
                                                value="1"
                                                onChange={() => {}}
                                                size="2"
                                                id="input-quantity"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col-xs-12 col-sm-5">
                                            <button
                                                type="button"
                                                id="button-buy"
                                                data-loading-text="Đang Xử lý..."
                                                className="btn btn-primary btn-lg btn-block"
                                                onClick={() => {}}
                                            >
                                                <i className="fa fa-check" aria-hidden="true"></i> Đặt hàng
                                            </button>
                                        </div>

                                        {/* <div className="col-xs-12 col-sm-4">
                                            <button
                                                type="button"
                                                id="button-cart"
                                                data-loading-text="Đang Xử lý..."
                                                className="btn btn-secondary btn-lg btn-block"
                                                onClick={() => {}}
                                            >
                                                <i className="fa fa-cart-plus" aria-hidden="true"></i> Thêm vào giỏ
                                            </button>
                                        </div> */}
                                        {this.renderAddToCartBtn()}
                                        {this.renderHotline()}
                                    </div>
                                </div>
                            </div>
                            {this.renderProductFeatures()}
                        </div>
                    </div>
                    {this.productDescription()}
                </div>
            </div>
        );
    };

    renderProductDetailInfo = () => {
        return (
            <table className="product-detail-info__table">
                <tbody>
                    {/* <tr className="manufacturers visible-xs">
                        <th>
                            <i className="fa fa-check-circle"></i>Hãng sản xuất:
                        </th>
                        <td>
                            <strong>{this.product.brand && this.product.brand.toUpperCase()}</strong>
                        </td>
                    </tr> */}
                    <tr className="model">
                        <th>
                            <i className="fa fa-check-circle"></i>Mã sản phẩm:
                        </th>
                        <td>
                            <strong>{this.product.productCode}</strong>
                        </td>
                    </tr>
                    <tr className="origin">
                        <th>
                            <i className="fa fa-check-circle"></i>Xuất xứ
                        </th>
                        <td>
                            <strong>Chính hãng</strong>
                        </td>
                    </tr>
                    <tr className="stock">
                        <th>
                            <i className="fa fa-check-circle"></i>Tình trạng:
                        </th>
                        <td>
                            <strong>{this.product.quantity > 0 ? "Còn hàng" : "Hết hàng"}</strong>
                        </td>
                    </tr>
                    <tr className="new-price">
                        <th>Giá khuyến mãi:</th>
                        <td>
                            <h3>{Number(this.product.discountPrice).toLocaleString("en-US")} đ</h3> <i>(chưa có VAT)</i>
                        </td>
                    </tr>
                    <tr className="old-price">
                        <th>Giá thị trường:</th>
                        <td>
                            <span>{Number(this.product.price).toLocaleString("en-US")} đ</span>
                            <div className="badge badge-warning ml-3">{productDiscountPrice(this.product.price, this.product.discountPrice)}</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    };

    renderHotline = () => {
        return (
            <div className="col-xs-12 col-sm-12">
                <div className="product-contact">
                    <ul>
                        <li>
                            <strong>Hotline</strong> -
                            <a href="tel:0963345099" className="product-contact__hotline">
                                <i className="fa fa-phone" aria-hidden="true"></i>
                                0963.345.099
                            </a>{" "}
                            -
                            <a
                                href="https://goo.gl/maps/9eCXiwoNbgXDCW6R7"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="product-contact__address"
                                title="Đường Tôn Đức Thắng, Khu phố 2, P Tiến Thành, TP Đồng Xoài, Bình Phước"
                            >
                                <i className="fa fa-map-marker" aria-hidden="true"></i>
                                Đường Tôn Đức Thắng, Khu phố 2, P Tiến Thành, TP Đồng Xoài, Bình Phước
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    };

    renderProductFeatures = () => {
        return (
            <ul className="product-detail-info__features">
                <li>
                    <div className="product-detail-info__features__item">
                        <div className="title">
                            <img src={require("../../assets/icons/icon-shop-features-1.png")} alt="Giao hàng miễn phí" />
                            Giao hàng miễn phí
                        </div>
                    </div>
                </li>
                <li>
                    <div className="product-detail-info__features__item">
                        <div className="title">
                            <img src={require("../../assets/icons/icon-shop-features-2.png")} alt="Cam kết chính hãng" />
                            Cam kết chính hãng
                        </div>
                    </div>
                </li>
                <li>
                    <div className="product-detail-info__features__item">
                        <div className="title">
                            <img src={require("../../assets/icons/icon-shop-features-3.png")} alt="Chiết khấu hấp dẫn" />
                            Chiết khấu hấp dẫn
                        </div>
                    </div>
                </li>
                <li>
                    <div className="product-detail-info__features__item">
                        <div className="title">
                            <img src={require("../../assets/icons/icon-shop-features-4.png")} alt="Thanh toán đơn giản" />
                            Thanh toán đơn giản
                        </div>
                    </div>
                </li>
            </ul>
        );
    };

    productDescription = () => {
        return (
            <div className="clearfix row">
                <div className="col-md-6">
                    <div className="product-detail-content -description">
                        <div className="title-section">
                            <h3>Chi tiết</h3>
                        </div>
                        <div>
                            <h2>
                                <div dangerouslySetInnerHTML={{ __html: this.product.description }} />
                            </h2>
                        </div>
                    </div>
                </div>
                {this.props.relatedProducts.length > 0 && (
                    <div className="col-md-6">
                        <div className="product-detail-content">
                            <div className="title-section">
                                <h3>Sản phẩm mua kèm</h3>
                            </div>
                            <div className="product-oneway-related-carousel product-carousel row flickity-enabled flickity-loaded flickity-resize">
                                {this.props.relatedProducts.map((product, index) => (
                                    <div className="col-xs-6 col-sm-4 col-md-4">
                                        <ProductCard
                                            key={`${product.productCode}-${index}`}
                                            product={product}
                                            currency={this.props.usedCurrencyProp}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    renderContent = () => {
        return (
            <React.Fragment>
                <div className="container mt-2 mb-4">
                    {this.renderBreadCrumbs()}
                    {this.renderTest()}
                </div>

                <HomeSale title="Sản phẩm tương tự" products={this.props.similarProducts} />
            </React.Fragment>
        );
    };

    render() {
        return <React.Fragment>{!this.product ? this.renderNotFoundProduct() : this.renderContent()}</React.Fragment>;
    }
}
const mapStateToProps = (state, ownProps) => {
    const productProps = state.products.find((product) => product.productCode === ownProps.match.params.productSlug);
    const searchSimilarProducts = productProps.subcategory ? productProps.subcategory : productProps.category;

    const relatedProducts = productProps.relatedProducts
        ? productProps.relatedProducts
              .map((productCode) => {
                  return state.products.find((product) => product.productCode === productCode);
              })
              .filter((product) => product !== undefined)
        : [];
    return {
        productProp: productProps,
        usedCurrencyProp: state.usedCurrency,
        showModal: state.productMaxShowModal,
        modalmessage: state.showModal,
        similarProducts: getProductsByFilter(state, VISIBILITY_FILTERS.SIMILAR, 6, searchSimilarProducts),
        relatedProducts: relatedProducts || [],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addProductToCartProp: (productDetails) => dispatch(addToCart(productDetails)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
