import React from "react";
import "./SwiperMenu.css";
const bathroomIcon = require(`../../../../assets/images/menu/bathroom.jpg`);
const kitchenIcon = require(`../../../../assets/images/menu/kitchen.png`);
const chandelierIcon = require(`../../../../assets/images/menu/chandelier.png`);
const electricIcon = require(`../../../../assets/images/menu/ceiling-fan.png`);
const SwiperMenu = () => {
    const menus = [
        {
            icon: bathroomIcon,
            title: "Phòng tắm",
            link: "/category/thiet-bi-phong-tam",
        },
        {
            icon: kitchenIcon,
            title: "Nhà bếp",
            link: "/category/thiet-bi-nha-bep",
        },
        {
            icon: chandelierIcon,
            title: "Đèn chùm",
            link: "",
        },
        {
            icon: electricIcon,
            title: "Thiết bị điện",
            link: "",
        },
    ];

    return (
        <section className="swiper-section">
            <div className="swiper-menu">
                <div className="swiper-container">
                    <div className="swiper-wrapper">
                        {menus.map((item) => {
                            return (
                                <div className="swiper-slide">
                                    <a href={item.link}>
                                        <div className="slide-item">
                                            <img className="slide-menu-img" src={item.icon} alt="product" />
                                            <h6 className="slide-text">{item.title}</h6>
                                        </div>
                                    </a>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SwiperMenu;
