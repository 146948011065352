import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { removeFromCart, clearCart, updateCartProductCount } from "../store/actions";
import CartProduct from "../components/Cart/CartProducts";
import CartProductTotals from "../components/Cart/CartProductTotals";
import OrderSuccess from "../components/OrderSuccess";
import PropTypes from "prop-types";
import { currencyToUse } from "../Utility/currency";
import CustomerInputs from "../components/Checkout/Forms/CustomerInputs";
import formValidator from "../Utility/formValidation";
import emailjs from "emailjs-com";

const Cart = (props) => {
    const [customerInfo, setCustomerInfo] = useState({
        firstName: {
            value: "",
            valid: false,
            touched: false,
            errorsMsg: "",
        },
        phoneNumber: {
            value: "",
            valid: false,
            touched: false,
            errorsMsg: "",
        },
        address: {
            value: "",
            valid: false,
            touched: false,
            errorsMsg: "",
        },
    });
    const [makeOrder, setMakeOrder] = useState(false);

    const customerInfoChangeHandler = (event, identifier) => {
        // use deep cloning to be able to get the values of nested objects
        const newCustomerInfo = { ...customerInfo };
        const customerInfoField = { ...newCustomerInfo[identifier] };
        customerInfoField.value = event.target.value;
        const validationResults = formValidator(identifier, customerInfoField.value);
        customerInfoField.valid = validationResults.isValid;
        customerInfoField.errorsMsg = validationResults.errorsMsg;
        customerInfoField.touched = true;
        newCustomerInfo[identifier] = customerInfoField;

        let makeOrder = true;
        for (let identifier in newCustomerInfo) {
            makeOrder = newCustomerInfo[identifier].valid && makeOrder;
        }
        setCustomerInfo(newCustomerInfo);
        setMakeOrder(makeOrder);
    };

    const productCountHandler = (field_value, product_details) => {
        props.updateCartProductCountProp(field_value, product_details);
    };

    let cartContent = null;
    let currencyKeys = currencyToUse(props.usedCurrencyProp);
    let currencyValue = currencyKeys.value;

    const renderCustomerInfo = () => {
        return (
            <div>
                <h4 className="mb-3">Thông tin thanh toán</h4>
                <form className="shop-form shop-bg-white p-3" noValidate>
                    {/* customer details form fields */}
                    <CustomerInputs customerInfo={customerInfo} inputChanged={(event, identifier) => customerInfoChangeHandler(event, identifier)} />
                </form>
            </div>
        );
    };

    const summaryOrder = () => {
        let totalPrice = 0;
        const orderInString = props.cartProductsProp.map((productInCart, index) => {
            let productFromStore = props.productProps.find((product) => product.productCode === productInCart.id);
            totalPrice += productFromStore.discountPrice * productInCart.quantity;
            return `${index + 1}. ${productFromStore.productCode} ${productFromStore.name} - ${productInCart.quantity} x ${Math.round(
                productFromStore.discountPrice
            ).toLocaleString()} = ${(productFromStore.discountPrice * productInCart.quantity).toLocaleString()}\n`;
        });
        return {
            orderInString: orderInString,
            totalPrice,
        };
    };

    const sendEmail = () => {
        const { totalPrice, orderInString } = summaryOrder();
        const templateParams = {
            message: `Khách hàng: ${customerInfo.firstName.value}\n 
            Số điện thoại: ${customerInfo.phoneNumber.value}.\n
            Địa chỉ: ${customerInfo.address.value} đã đặt hàng.\n\n
            Chi tiết đơn hàng:\n\n
            ${orderInString} \n\n
            Tổng tiền: ${totalPrice.toLocaleString()} ${currencyKeys.name}.\n\n
            `,
            from_name: `${customerInfo.firstName.value}`,
        };

        emailjs.send("service_k1xrz5p", "template_nflaqcw", templateParams, "5LxrqV-xDaJuzq8lm").then(
            (result) => {
                console.log(result.text);
                alert("Bạn đã đặt hàng thành công, chúng tôi sẽ liên hệ với bạn sớm nhất có thể!");
            },
            (error) => {
                console.log(error.text);
                alert("Hệ thống đang bận, vui lòng thử lại sau!");
            }
        );
    };

    if (props.cartTotalProp > 0) {
        let cartPriceCountArray = [];
        let cartProducts = props.cartProductsProp.map((productInCart, index) => {
            // fetch product information from source based on id
            // product information can also be stored in state
            let productFromStore = props.productProps.find((product) => product.productCode === productInCart.id);
            cartPriceCountArray.push({
                price: productFromStore.quantity > 0 ? Math.round(productFromStore.discountPrice * currencyValue) : 0,
                count: productInCart.quantity,
            });
            return (
                <CartProduct
                    key={index}
                    productCode={productFromStore.productCode}
                    productName={productFromStore.name}
                    productVendor={productFromStore.brand}
                    productCategory={productFromStore.category}
                    productPhoto={productFromStore.imageUrl}
                    productPrice={Math.round(productFromStore.price * currencyValue)}
                    discountPrice={Math.round(productFromStore.discountPrice * currencyValue)}
                    productCount={productInCart.quantity}
                    productSize={productInCart.size}
                    productQuantity={productFromStore.quantity}
                    updateProductCount={(event) => productCountHandler(event.target.value, productInCart)}
                    removeCartProduct={() => props.removeProductFromCartProp(productInCart)}
                    currency={props.usedCurrencyProp}
                />
            );
        });

        let cartTotals = (
            <CartProductTotals
                subtotal={cartPriceCountArray.reduce((acc, el) => acc + el.price * el.count, 0)}
                vat={props.vatProp}
                clearCart={() => props.clearProductsFromCartProp()}
                currency={props.usedCurrencyProp}
                onConfirm={sendEmail}
            />
        );

        cartContent = (
            <React.Fragment>
                {/* // responsive layout for cart products and totals */}
                {/* left is customer info form, right is cart products and totals */}
                {/* code here */}
                <div className="row">
                    <div className="col-md-8">
                        <h4 className="mb-5">Xác nhận đơn hàng </h4>
                        {cartProducts}
                        {cartTotals}
                    </div>
                    <div className="col-md-4">{renderCustomerInfo()}</div>
                </div>
            </React.Fragment>
        );
    } else if (props.cartTotalProp === 0 && props.orderSuccessProp) {
        cartContent = <OrderSuccess />;
    } else {
        cartContent = (
            <h5 className={"shop-empty-cart"}>
                Chưa có sản phẩm nào trong giỏ hàng. <Link to={"/all"}>Hãy tiếp tục mua sắm nhé.</Link>
            </h5>
        );
    }

    return (
        <div className="container shop-container py-4">
            <div className={"p-4 shop-div"}>{cartContent}</div>
        </div>
    );
};

const mapStateToProps = (state) => {
    console.log(state);
    return {
        productProps: state.products,
        cartTotalProp: state.cartTotal,
        cartProductsProp: state.cart,
        vatProp: state.vat,
        orderSuccessProp: state.orderSuccess,
        usedCurrencyProp: state.usedCurrency,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        removeProductFromCartProp: (productDetails) => dispatch(removeFromCart(productDetails)),
        clearProductsFromCartProp: () => dispatch(clearCart()),
        updateCartProductCountProp: (value, productDetails) => dispatch(updateCartProductCount(Number(value), productDetails)),
    };
};

Cart.propTypes = {
    cartTotalProp: PropTypes.number.isRequired,
    cartProductsProp: PropTypes.array.isRequired,
    productProps: PropTypes.array.isRequired,
    orderSuccessProp: PropTypes.bool.isRequired,
    vatProp: PropTypes.number,
    usedCurrencyProp: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
