import React, { Component } from "react";
import { connect } from "react-redux";
import ItemBanners from "./components/ItemBanners";
import HomeSale from "./components/HomeSale/HomeSale";
import Loader from "../../components/Loader/Index";
import "./Home.css";
import Carousel from "../../components/UI/Carousel/Carousel";
import SwiperMenu from "./components/SwiperMenu/SwiperMenu";
import { setAllProducts } from "../../store/actions";
import { CATEGORIES_TYPES, VISIBILITY_FILTERS } from "../../static/constants";
import { getProductsByFilter } from "../../store/selectors";
import ShopFeatures from "../../components/ShopFeatures/ShopFeatures";
import CatalogBanner from "../../components/CatalogBanner/CatalogBanner";
import CatalogBanner1 from "../../components/CatalogBanner/CatalogBanner1";

class Home extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
        };
    }

    render() {
        return this.state.loading ? (
            <Loader />
        ) : (
            <React.Fragment>
                <Carousel />

                {/* <SwiperMenu /> */}
                <ShopFeatures />

                <div className="container">
                    <div className="clearfix"></div>

                    <CatalogBanner title={"Thiết bị vệ sinh"} categories={CATEGORIES_TYPES.TBVS} />
                    <CatalogBanner title={"Hãng thiết bị vệ sinh"} categories={CATEGORIES_TYPES.BRAND_TBVS} />
                    <CatalogBanner title={"Thiết bị bếp"} categories={CATEGORIES_TYPES.TBBEP} />
                    <CatalogBanner title={"Hãng thiết bị bếp"} categories={CATEGORIES_TYPES.BRAND_TBBEP} />
                    {/* <CatalogBanner1 /> */}
                </div>

                <HomeSale title="Sản phẩm nổi bật" description="Top những sản phẩm nổi bật nhất." products={this.props.saleProducts} />
                {/* <SelloutCards /> */}
                {/* <Banner /> */}
                <ItemBanners />
                <HomeSale title="Sản phẩm bán chạy" description="Top sản phẩm bán chạy nhất." products={this.props.highlightedProducts} />
                {/* <Deal /> */}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        saleProducts: getProductsByFilter(state, VISIBILITY_FILTERS.SALE, 6),
        highlightedProducts: getProductsByFilter(state, VISIBILITY_FILTERS.HIGHLIGHTED, 6),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAllProducts: (products) => dispatch(setAllProducts(products)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
