import React from 'react';
import { NavLink } from 'react-router-dom';
import './Banner.css';

const ItemBanners = () => {
    let itemData = [
        {
            image: 'banner_1.jpg',
            title: 'Thiết bị vệ sinh cao cấp',
            text: `Khuyến mãi`,
            link: '/category/kids',
            percentage: '30'
        },
        {
            image: 'banner_2.jpg',
            title: 'Đèn trang trí cao cấp',
            text: `Khuyến mãi`,
            link: '/category/men',
            percentage: '50'
        }
    ];
    function generateItemBanners() {
        return itemData.map((item, index) => {
            return (
                <div className="col-md-6 px-3">
                    <div className="row">
                        <div className="item-container shadow" key={index}>
                            <div className="item-content">
                                <p className="item-banner-title my-1">
                                    {item.title}
                                </p>
                                <h4 className="mb-3">
                                    {item.text} <br /> Lên đến {item.percentage}
                                    %
                                </h4>
                                <NavLink
                                    className="btn btn-primary btn-sm"
                                    to={item.link}
                                    exact>
                                    Mua ngay
                                </NavLink>
                            </div>
                            <img
                                className="banner-image"
                                src={require(`../../../assets/images/${item.image}`)}
                                alt="banner"
                            />
                        </div>
                    </div>
                </div>
            );
        });
    }
    return (
        // <div className="container item-banners mb-4">
        //     {generateItemBanners()}
        // </div>
        <section className="banners-section container">
            <div className="container-fluid">
                <div className="row">{generateItemBanners()}</div>
            </div>
        </section>
    );
};

export default ItemBanners;
