import React from "react";
import PropTypes from "prop-types";
import AddToWishList from "../../components/AddToWishlist/AddToWishlist";
import { currencyToUse } from "../../Utility/currency";

const cartProducts = (props) => {
    let currencyKeys = currencyToUse(props.currency);
    let currencyName = currencyKeys.name;
    return (
        <React.Fragment>
            <div className="row">
                <div className="col">
                    <div className="row">
                        <div className="col-md-2 shop-cart-image-div">
                            <img className={"shop-cart-image"} src={props.productPhoto} alt={props.productName} />
                        </div>

                        <div className="col-sm-5 col-md-4 shop-cart-product-details">
                            <div className="wishlist-container">
                                <AddToWishList productId={props.productCode} title={"add to wishlist"} classStyleName={"product-wishlist"} />
                            </div>
                            {props.productVendor ? (
                                <h6 className="shop-cart-category">
                                    {`Thương hiệu : `}
                                    <span className="text-caitalize">{props.productVendor}</span>
                                </h6>
                            ) : null}

                            <h5 className="shop-cart-name text-capitalize">{`${props.productName} ${props.productCode}`}</h5>

                            {props.productSize ? (
                                <h6 className="shop-cart-category text-capitalize">
                                    {`Kích thước : `}
                                    {props.productSize}
                                </h6>
                            ) : null}

                            <div>
                                <span className={"badge " + (props.productQuantity > 0 ? "badge-success" : "badge-danger")}>
                                    {props.productQuantity > 0 ? "Còn hàng" : "Tạm hết hàng"}
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-7 col-md-6">
                            <div className="row">
                                <div className="col-sm-6 text-left">
                                    {props.productQuantity > 0 ? (
                                        <span>
                                            <h6 className={"shop-cart-item-price"}>
                                                <span style={{ textDecoration: "line-through" }}>{props.productPrice.toLocaleString()}</span>
                                                <span style={{ textTransform: "capitalize" }}>{currencyName}</span>
                                                {props.discountPrice.toLocaleString()}
                                                <span style={{ textTransform: "capitalize" }}>{currencyName}</span>
                                            </h6>
                                            <input
                                                type="number"
                                                name="quantity"
                                                value={props.productCount}
                                                onChange={props.updateProductCount}
                                                size="2"
                                                id="input-quantity"
                                                className="form-control"
                                            />
                                            <h6 className={"shop-cart-item-total"}>
                                                {`Tổng cộng: `}

                                                <span style={{ textTransform: "capitalize" }}>{`${(
                                                    props.discountPrice * props.productCount
                                                ).toLocaleString()} ${currencyName}`}</span>
                                            </h6>
                                        </span>
                                    ) : null}
                                </div>
                                <div className="col-sm-4 offset-sm-2 shop-cart-b-container">
                                    <button type="button" onClick={props.removeCartProduct} className="btn shop-btn-warning btn-sm">
                                        Xóa
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
        </React.Fragment>
    );
};

cartProducts.propTypes = {
    productCode: PropTypes.number.isRequired,
    productPhoto: PropTypes.string.isRequired,
    productName: PropTypes.string.isRequired,
    productVendor: PropTypes.object,
    productCategory: PropTypes.string.isRequired,
    productPrice: PropTypes.number.isRequired,
    discountPrice: PropTypes.number.isRequired,
    updateProductCount: PropTypes.func.isRequired,
    productQuantity: PropTypes.number.isRequired,
    removeCartProduct: PropTypes.func.isRequired,
    currency: PropTypes.object.isRequired,
};

export default cartProducts;
